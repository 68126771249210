exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-donation-requests-fail-jsx": () => import("./../../../src/pages/about-us/donation-requests/fail.jsx" /* webpackChunkName: "component---src-pages-about-us-donation-requests-fail-jsx" */),
  "component---src-pages-about-us-donation-requests-index-jsx": () => import("./../../../src/pages/about-us/donation-requests/index.jsx" /* webpackChunkName: "component---src-pages-about-us-donation-requests-index-jsx" */),
  "component---src-pages-about-us-donation-requests-success-jsx": () => import("./../../../src/pages/about-us/donation-requests/success.jsx" /* webpackChunkName: "component---src-pages-about-us-donation-requests-success-jsx" */),
  "component---src-pages-account-addresses-jsx": () => import("./../../../src/pages/account/addresses.jsx" /* webpackChunkName: "component---src-pages-account-addresses-jsx" */),
  "component---src-pages-account-index-jsx": () => import("./../../../src/pages/account/index.jsx" /* webpackChunkName: "component---src-pages-account-index-jsx" */),
  "component---src-pages-account-login-tsx": () => import("./../../../src/pages/account/login.tsx" /* webpackChunkName: "component---src-pages-account-login-tsx" */),
  "component---src-pages-account-orders-jsx": () => import("./../../../src/pages/account/orders.jsx" /* webpackChunkName: "component---src-pages-account-orders-jsx" */),
  "component---src-pages-account-register-jsx": () => import("./../../../src/pages/account/register.jsx" /* webpackChunkName: "component---src-pages-account-register-jsx" */),
  "component---src-pages-account-reset-password-tsx": () => import("./../../../src/pages/account/reset-password.tsx" /* webpackChunkName: "component---src-pages-account-reset-password-tsx" */),
  "component---src-pages-account-settings-jsx": () => import("./../../../src/pages/account/settings.jsx" /* webpackChunkName: "component---src-pages-account-settings-jsx" */),
  "component---src-pages-account-verify-account-jsx": () => import("./../../../src/pages/account/verify-account.jsx" /* webpackChunkName: "component---src-pages-account-verify-account-jsx" */),
  "component---src-pages-email-updates-index-jsx": () => import("./../../../src/pages/email-updates/index.jsx" /* webpackChunkName: "component---src-pages-email-updates-index-jsx" */),
  "component---src-pages-email-updates-success-jsx": () => import("./../../../src/pages/email-updates/success.jsx" /* webpackChunkName: "component---src-pages-email-updates-success-jsx" */),
  "component---src-pages-international-jsx": () => import("./../../../src/pages/international.jsx" /* webpackChunkName: "component---src-pages-international-jsx" */),
  "component---src-pages-logout-jsx": () => import("./../../../src/pages/logout.jsx" /* webpackChunkName: "component---src-pages-logout-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-support-register-fail-jsx": () => import("./../../../src/pages/support/register/fail.jsx" /* webpackChunkName: "component---src-pages-support-register-fail-jsx" */),
  "component---src-pages-support-register-index-jsx": () => import("./../../../src/pages/support/register/index.jsx" /* webpackChunkName: "component---src-pages-support-register-index-jsx" */),
  "component---src-pages-support-register-success-jsx": () => import("./../../../src/pages/support/register/success.jsx" /* webpackChunkName: "component---src-pages-support-register-success-jsx" */),
  "component---src-pages-where-to-buy-jsx": () => import("./../../../src/pages/where-to-buy.jsx" /* webpackChunkName: "component---src-pages-where-to-buy-jsx" */),
  "component---src-templates-article-detail-jsx": () => import("./../../../src/templates/article-detail.jsx" /* webpackChunkName: "component---src-templates-article-detail-jsx" */),
  "component---src-templates-article-list-jsx": () => import("./../../../src/templates/article-list.jsx" /* webpackChunkName: "component---src-templates-article-list-jsx" */),
  "component---src-templates-collections-jsx": () => import("./../../../src/templates/collections.jsx" /* webpackChunkName: "component---src-templates-collections-jsx" */),
  "component---src-templates-press-room-detail-jsx": () => import("./../../../src/templates/press-room-detail.jsx" /* webpackChunkName: "component---src-templates-press-room-detail-jsx" */),
  "component---src-templates-press-room-list-jsx": () => import("./../../../src/templates/press-room-list.jsx" /* webpackChunkName: "component---src-templates-press-room-list-jsx" */),
  "component---src-templates-preview-jsx": () => import("./../../../src/templates/preview.jsx" /* webpackChunkName: "component---src-templates-preview-jsx" */),
  "component---src-templates-product-detail-jsx": () => import("./../../../src/templates/product-detail.jsx" /* webpackChunkName: "component---src-templates-product-detail-jsx" */),
  "component---src-templates-prostaff-category-jsx": () => import("./../../../src/templates/prostaff-category.jsx" /* webpackChunkName: "component---src-templates-prostaff-category-jsx" */),
  "component---src-templates-prostaff-profile-jsx": () => import("./../../../src/templates/prostaff-profile.jsx" /* webpackChunkName: "component---src-templates-prostaff-profile-jsx" */),
  "component---src-templates-sanity-page-jsx": () => import("./../../../src/templates/sanity-page.jsx" /* webpackChunkName: "component---src-templates-sanity-page-jsx" */),
  "component---src-templates-sanity-shared-page-jsx": () => import("./../../../src/templates/sanity-shared-page.jsx" /* webpackChunkName: "component---src-templates-sanity-shared-page-jsx" */)
}

